((FUNCTIONS) => {
	const a = 'is-active';
	const v = 'is-visible';

	// ＜ルート設定対応＞要素が空だったら削除
	const removeElmIfEmpty = () => {
		const $eleList = document.querySelectorAll('.js-checkEmptyElm');
		$eleList.forEach(ele => {
			if (!ele.textContent.length) {
				ele.remove();
			}
		});
	}
	// ＜ルート設定対応＞該当のリストにルート設定出力が0件の場合、指定する親ブロックごと削除する
	const removeBlockIfListEmpty = () => {
		const $eleList = document.querySelectorAll('.js-checkEmptyList');
		if ($eleList) {
			$eleList.forEach(ele => {
				if (!ele.getElementsByTagName('li')) {
					ele.closest('.js-checkEmptyList-wrap').remove();
				}
			});
		}
	};

	removeElmIfEmpty();
	removeBlockIfListEmpty();

})(window.FUNCTIONS);
