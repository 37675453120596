(() => {
	const siteName = 'default-package_2023_vanilla';
	const htmlHasClass = (_class) => { return document.documentElement.classList.contains(_class); };
	const a = 'is-active';
	const v = 'is-visible';
	const f = 'is-fixed';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			isMobile: htmlHasClass('ua-mobile'),
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: window.innerWidth,
				height: window.innerHeight
			},
			header: {
				desktop: 100,
				sp: 60
			}
		},
		localDecision() {
			const regExp = new RegExp(`(test\\.${siteName}\\.test\\.co\\.jp|daijobuda\\.jp|localhost|192\\.168\\.)`, 'g');
			return regExp.test(location.hostname);
		},
		localLoading() { // ローカル環境ならincludeをAjaxで読み込む
			const _this = this;
			const key = 'inc_';
			let ajaxArray = [];
			let ajaxArrayList = [];
			let includeClass = document.querySelectorAll(`[class*="${key}"]`);

			includeClass.forEach(ele => {
				let path = ele.innerHTML.split(' ')[1];
				ajaxArray.push(path);
			});

			if (ajaxArray.length) {
				ajaxArray.forEach(ele => {
					let xhr = new XMLHttpRequest();
					let promise = new Promise((resolve, reject) => {
						xhr.onreadystatechange = function () {
							if (xhr.readyState === 4) {
								if (xhr.status === 200) {
									resolve(xhr.responseText);
								} else {
									reject(xhr.statusText);
								}
							}
						};
					});
					xhr.open('GET', ele + '.html', true);
					xhr.send();
					ajaxArrayList.push(promise);
				});

				Promise.all(ajaxArrayList).then(results => {
					let regExp = new RegExp(key);

					results.forEach(ele => {
						let classList = ele.split(/ |\"/g);
						let position;

						classList.forEach(ele2 => {
							if (ele2.match(regExp)) { position = ele2; }
						});
						document.querySelectorAll('.' + position).forEach(ele2 => {
							ele2.outerHTML = ele;
						});
						console.log('Succeeded to read the include file!:', position);
					});

					_this.loadDelayScript();
				}).catch((error) => {
					console.error('Failed to read the include file:', error);
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		getQuery() {
			let vars = [];
			let hash = null;
			let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			hashes.forEach(ele => {
				hash = ele.split('=');
				hash[1] = (hash[1].indexOf('#') !== -1) ? hash[1].split('#')[0] : hash[1];
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			});
			return vars;
		},
		nextToggle() {
			const switchElements = document.querySelectorAll('.js-toggle');

			switchElements.forEach(switchElement => {
				switchElement.addEventListener('click', e => {
					e.currentTarget.classList.toggle(a);

					let toggleBlock = e.currentTarget.nextElementSibling;
					toggleBlock.classList.toggle(a);
					if (toggleBlock.style.height) {
						toggleBlock.style.height = null;
						toggleBlock.style.visibility = 'hidden';
					} else {
						toggleBlock.style.height = toggleBlock.scrollHeight + 'px';
						toggleBlock.style.visibility = 'visible';
					}
				});
			});
		},
		tabSwitch() {
			let $tab = document.querySelectorAll('.js-tab');
			let $content = document.querySelectorAll('.js-tab-content');
			if ($tab.length) {
				// ハッシュタグで絞り込み
				if (location.hash) {
					let hash = location.hash.split('#')[1];
					$tab.forEach(ele => {
						ele.classList.remove(a);
						if (ele.getAttribute('data-target').match(hash)) {
							ele.classList.remove(a);
						}
					});
					$content.forEach(ele => {
						ele.classList.remove(a);
						ele.classList.remove(v);
						if (ele.getAttribute('data-target').match(hash)) {
							$(ele).addClass(a);
							setTimeout(() => { ele.classList.add(v); }, 200);
						}
					});
				}

				$tab.forEach(ele => {
					ele.addEventListener('click', () => {
						$tab.forEach(ele2 => { ele2.classList.remove(a); })
						ele.classList.add(a);
						$content.forEach(ele => {
							ele.classList.remove(a);
							ele.classList.remove(v);
						});

						let $target;
						let target = ele.getAttribute('data-target');
						if (target === 'all') {
							$target = $content;
						} else {
							$target = document.querySelectorAll('[data-target="' + target + '"]');
						}
						$target.forEach(ele => {
							ele.classList.add(a);
							setTimeout(() => { ele.classList.add(v); }, 200);
						});
					});
				});
			}
		},
		easeScroll() {
			const scrollToObject = (_self) => {
				let hash = _self.currentTarget.href.split('#')[1];
				let pos = hash === 'pagetop' ? 0 : document.getElementById(hash).getBoundingClientRect().top;
				let offset = hash === 'pagetop' ? 0 : window.scrollY;
				pos += offset;

				if (hash !== 'pagetop') {
					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						pos = pos - FUNCTIONS.va.header.sp;
					} else {
						pos = pos - FUNCTIONS.va.header.desktop;
					}
				}
				window.scrollTo({ top: pos, behavior: 'smooth' });
			}
			// フォーカス用関数
			const scrollFocus = (_hash) => {
				let hash = _hash;
				if (hash !== 'pagetop') {
					const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
					const focusableElements = Array.from(
						document.getElementById(hash).querySelectorAll(focusableElementsSelector)
					);
					if(focusableElements.length) {
						focusableElements[0].focus();
					}
				}
			};
			// スクロール完了後に実行する関数
			let scrollResolveTimeout;
			const waitForScrollComplete = (_hash) => {
			  return new Promise((resolve) => {
				const scrollTimeout = setTimeout(() => {
				  resolve(true);
				}, 100);
				const scrollTimeoutEvent = addEventListener('scroll', function fn (e) {
					clearTimeout(scrollTimeout);
					clearTimeout(scrollResolveTimeout);
					scrollResolveTimeout = setTimeout(function () {
						scrollFocus(_hash);
						removeEventListener('scroll', fn);
						resolve(true);
					}, 100);
				});
			  });
			};
			document.querySelectorAll('a[rel="scroll"]').forEach((ele) => {
				ele.addEventListener('click', async (e) => {
					e.preventDefault();
					if (/#/.test(e.currentTarget.href)) {
						scrollToObject(e);
						let hash = e.currentTarget.href.split('#')[1];
						await waitForScrollComplete(hash);
						return false;
					}
				});
			});
		},
		easeScrollHash() {
			const hash = location.hash;
			if (hash) {
				// フォーカス用関数
				const scrollFocus = (_hash) => {
					let hash = _hash;
					if (hash !== 'pagetop') {
						const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
						const focusableElements = Array.from(
							document.getElementById(hash).querySelectorAll(focusableElementsSelector)
						);
						if(focusableElements.length) {
							focusableElements[0].focus();
						}
					}
				}
				// スクロール完了後に実行する関数
				let scrollResolveTimeout;
				const waitForScrollComplete = (_hash) => {
					return new Promise((resolve) => {
						const scrollTimeout = setTimeout(() => {
						resolve(true);
						}, 100);
						const scrollTimeoutEvent = addEventListener('scroll', function fn (e) {
							clearTimeout(scrollTimeout);
							clearTimeout(scrollResolveTimeout);
							scrollResolveTimeout = setTimeout(function () {
								scrollFocus(_hash);
								removeEventListener('scroll', fn);
								resolve(true);
							}, 100);
						});
					});
				}
				window.addEventListener('load', async () => {
					document.documentElement.style.display = 'none';
					document.body.style.display = 'none';

					setTimeout(() => {
						document.documentElement.style.display = 'block';
						document.body.style.display = 'block';

						let pos = document.getElementById(hash.split('#')[1]).getBoundingClientRect().top;

						if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
							pos -= FUNCTIONS.va.header.sp;
						} else {
							pos -= FUNCTIONS.va.header.desktop;
						}
						window.scrollTo({ top: pos, behavior: 'smooth' });
					}, 100);
					await waitForScrollComplete(hash);
				});
			}
		},
		pageTop() {
			let footer = document.querySelector('.footer');
			let $pagetop = document.querySelector('.footer-pagetop');

			if ($pagetop) {
				window.addEventListener('scroll', (e) => {
					if (window.scrollY > '100') {
						$pagetop.classList.add(v);
					} else {
						$pagetop.classList.remove(v);
					}
				});
			}
		},
		setImgAttrWidthHeight() {
			const getImg = (_src) => {
				return new Promise((resolve, reject) => {
					const image = new Image();
					image.src = _src;
					image.onload = () => { resolve(image); }
					image.onerror = (error) => { reject(error); }
				});
			};

			const imgs = document.getElementsByTagName('img');

			for (const img of imgs) {
				const src = img.getAttribute('src');
				getImg(src).then((res) => {
					if (!img.hasAttribute('width')) {
						img.setAttribute('width', res.width);
					}
					if (!img.hasAttribute('height')) {
						img.setAttribute('height', res.height);
					}
				})
					.catch((error) => { console.log(error); });
			}
		},
		setModal() {
			const createModal = (_appendHTML,_target) => {
				const $container = document.createElement('div');
				$container.className = 'mod-modal-overlay';
				$container.innerHTML = _appendHTML;
				document.body.appendChild($container);

				$container.classList.add(a);
				setTimeout(() => {
					$container.classList.add(v);
					document.querySelectorAll('.mod-modal-closeArea, .mod-modal-closeBtn, .mod-modalContent a').forEach((ele) => {
						ele.addEventListener('click', (e) => {
							$container.classList.remove(v);
							setTimeout(() => {
								$container.classList.remove(a);
								$container.remove();
								_target.focus();
							}, 400);
						});
					});
					window.addEventListener("keydown", function (event) {
						if(document.querySelectorAll('.mod-modal-overlay').length) {
							// タブキーが押された時
							if (event.key === "Tab") {
								event.preventDefault();
								// モーダル要素内のフォーカス可能な要素の一覧を取得
								const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
								const focusableElements = Array.from(
								document.querySelector('.mod-modal-overlay').querySelectorAll(focusableElementsSelector)
								);
								// 現在のフォーカス位置を取得
								const focusedItemIndex = focusableElements.indexOf(document.activeElement);
								// shiftキーと同時に押されてた場合
								if (event.shiftKey) {
								if (focusedItemIndex === 0) {
									// 現在のフォーカスが最初の要素の場合、最後の要素にフォーカスを移動
									focusableElements[focusableElements.length - 1].focus();
								} else {
									// 現在のフォーカスが最初の要素以外の場合、前の要素にフォーカスを移動
									focusableElements[focusedItemIndex - 1].focus();
								}
								} else {
								if (focusedItemIndex === focusableElements.length - 1) {
									// 現在のフォーカスが最後の要素の場合、最初の要素にフォーカスを移動
									focusableElements[0].focus();
								} else {
									// 現在のフォーカスが最後の要素以外の場合、次の要素にフォーカスを移動
									focusableElements[focusedItemIndex + 1].focus();
								}
								}
							}
							// ESCキーが押された時
							if (event.key === "Escape") {
								event.preventDefault();
								$container.classList.remove(v);
								setTimeout(() => {
									$container.classList.remove(a);
									$container.remove();
									_target.focus();
								}, 400);
							}
						}
					});
				});
			};
			document.querySelectorAll('.mod-modal').forEach((ele) => {
				ele.addEventListener('click', (e) => {
					let targetKey = e.currentTarget.getAttribute('data-target');
					let $content = document.querySelector(`.mod-modalContent[data-target="${targetKey}"]`);
					if ($content.outerHTML) {
						let appendHTML = `<div class="mod-modal-closeArea"></div>
							<button type="button" class="mod-modal-closeBtn" aria-label="close"><span aria-hidden="true"></span></button>
							${$content.outerHTML}`;
						createModal(appendHTML,e.currentTarget);
					}
					return false;
				});
			});

			// 画像1個だけのモーダル
			document.querySelectorAll('.mod-modalImg').forEach((ele) => {
				ele.addEventListener('click', (e) => {
					let appendHTML = `<div class="mod-modal-closeArea"></div>
						<button type="button" class="mod-modal-closeBtn" aria-label="close"><span aria-hidden="true"></span></button>
						${e.currentTarget.querySelector('img').outerHTML}`;
					createModal(appendHTML,e.currentTarget);
					return false;
				});
			});
		},
		matchHeight() {
			const $elements = document.querySelectorAll( '[data-mh]' );
			const groups = new Map();

			$elements.forEach( ele => { ele.style.height = 'auto'; } ); // 初期化

			$elements.forEach( ele => {
				const attributeName = ele.getAttribute( 'data-mh' );
				if ( !groups.has( attributeName ) ) {
					groups.set( attributeName, [] );
				}
				groups.get( attributeName ).push( ele );
			} );
			groups.forEach( group => {
				const attributeGroups = new Map();
				group.forEach( ele => {
					const attributeName = ele.getAttribute( 'data-mh' );
					const topPosition = ele.getBoundingClientRect().top;
					const key = attributeName + '_' + topPosition;
					if ( !attributeGroups.has( key ) ) {
						attributeGroups.set( key, [] );
					}
					attributeGroups.get( key ).push( ele );
				} );
				attributeGroups.forEach( (item) => {
					const maxHeight = Math.max( ...item.map( ele => ele.offsetHeight ) );
					item.forEach( (ele2) => {
						ele2.style.height = `${maxHeight}px`;
					});
				});
			} );
		},
		inViewAnimation() {
			const target = document.querySelectorAll('.js-fade');
			const options = {
				root: null,
				rootMargin: '-30% 0px',
				threshold: 0
			};
			const callback = (entries, observer) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						entry.target.classList.add(v);
					}
				});
			};
			const observer = new IntersectionObserver(callback, options);

			target.forEach(ele => {
				observer.observe(ele);
			});
		},
		headerHamburger() {
			const $header = document.querySelector('.header');
			const $hamburger = document.querySelector('.header-hamburger');
			const $nav = document.querySelector('.header-nav');
			const $container = document.querySelector('.container');
			let scrollTopNow;

			const fixedOnContainer = () => {
				scrollTopNow = window.scrollY;
				$container.style.position = 'fixed';
				$container.style.width = '100%';
				$container.style.top = -1 * scrollTopNow;
				$container.style.left = 0;
				document.body.classList.add(f);
			};
			const fixedOffContainer = () => {
				$container.style.position = 'static';
				document.body.classList.remove(f);
				window.scrollTo({ top: scrollTopNow });
			};

			if ($hamburger) {
				$hamburger.addEventListener('click', (e) => {
					e.currentTarget.classList.toggle(a);
					if ($nav.classList.contains(a)) {
						$nav.classList.remove(v);
						$nav.classList.remove(a);
						$header.classList.remove(a);
						fixedOffContainer();
					} else {
						fixedOnContainer();
						$nav.classList.add(a);
						$header.classList.add(a);
						setTimeout(() => { $nav.classList.add(v); });
					}
				});
			}
		},
		headerScroll(){
			let header = document.querySelector('.header');
			let timer, flag;

			window.addEventListener('scroll', (e) => {
				let thisScroll = window.scrollY;

				if (timer) clearTimeout(timer);
				if (thisScroll === 0 && flag) {
					header.classList.remove('is-move');
					flag = false;
				} else if (thisScroll > 0 && !flag) {
					header.classList.add('is-move');
					flag = true;
				}
			});

		},
		loadDelayScript() {
			let _this = this;
			_this.nextToggle();
			_this.tabSwitch();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.setImgAttrWidthHeight();
			_this.setModal();
			_this.inViewAnimation();
			_this.headerHamburger();
			_this.headerScroll();
			let resizeTime = 0;
			window.addEventListener('load', () => { _this.matchHeight(); });
			window.addEventListener('resize', () => {
				clearTimeout(resizeTime);
				resizeTime = setTimeout(() => { _this.matchHeight(); }, 300);
			});
		}
	};

	document.addEventListener('DOMContentLoaded', () => FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript());
})();
